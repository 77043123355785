<template>
  <div>
    <v-card
      v-for="sucursal in sucursales"
    :key="sucursal.id"
      max-width="250"
      class="mx-4 my-4"
    >
      <v-card-text>
          <p class="display-1 text--primary">
            {{ sucursal.nombre_sucursal }}
          </p>
          <div class="text-primary">
            calle: {{ sucursal.direccion.calle }} <br>
            Exterior: {{ sucursal.direccion.numero_exterior }} / Interior: {{ sucursal.direccion.numero_interior}} <br>

            colonia: {{ sucursal.direccion.colonia }}<br>
            {{ sucursal.direccion.ciudad }} - CP:{{ sucursal.direccion.codigo_postal}} <br>
            Número de telefono: {{ sucursal.direccion.numero_contacto }} 
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            text
            color="primary"
            @click="$emit('sucursalEdit', sucursal)"
          >
            Editar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$emit('deleteSucursal', sucursal.id)"
          >
            Eliminar 
          </v-btn>
        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    sucursales: Array
  }
}
</script>